<template>
  <v-layout v-if="HTM != null" wrap>
    <Header titulo="Aprende con Nosotros" :imagen="$store.state.headerOtras" />
    <Separador titulo="Realiza tus Manualidades" />
    <Paginacion Hazlo :array="HTM" :slice="12" />
  </v-layout>
</template>

<script>
import Paginacion from "../components/Paginacion";
import Separador from "../components/Separador";
import Header from "../components/Header.vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["HTM"]),
  },
  metaInfo: {
    title: "Hazlo Tú Mismo | Ofimania",
  },
  created() {
    this.$store.state.cargando = true;
    if (this.HTM == null) {
      this.axios
        .get("https://www.ofimania.com/API/HazloTuMismo/Get")
        .then((response) => {
          this.$store.state.HTM = response.data;
          this.$store.state.cargando = false;
        })
        .catch((error) => {
          this.$store.state.cargando = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet",
          };
        });
    } else {
      this.$store.state.cargando = false;
    }
  },
  components: {
    Paginacion,
    Separador,
    Header,
  },
};
</script>

<style></style>
